const local = {
  debug: true,
  console_url: 'http://localhost:3000',
  api_url: 'https://service-dev.mop-api.morrison.express',
  // api_url: 'http://localhost:5000',
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_zStfboGM0',
    APP_CLIENT_ID: '39rgrvglpuvkapmuajnj9fg66b',
    IDENTITY_POOL_ID: '',
  },
  ga_id: 'UA-142071679-3',
  sentry_env: 'local',
}

const dev = {
  console_url: 'https://one-dev.morrison.express',
  api_url: 'https://service-dev.mop-api.morrison.express',
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_zStfboGM0',
    APP_CLIENT_ID: '3c96k6vj3kddna70m62trdqrqd',
    IDENTITY_POOL_ID: '',
  },
  ga_id: 'UA-142071679-3',
  sentry_env: 'dev',
}

const uat = {
  console_url: 'https://one-uat.morrison.express',
  api_url: 'https://service-uat.mop-api.morrison.express',
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_zStfboGM0',
    APP_CLIENT_ID: '3o3k857cgnn7vcllbft85gr917',
    IDENTITY_POOL_ID: '',
  },
  ga_id: 'UA-142071679-3',
  sentry_env: 'uat',
}

const prod = {
  console_url: 'https://one.morrison.express',
  api_url: 'https://service.mop-api.morrison.express',
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_zStfboGM0',
    APP_CLIENT_ID: '6at9skr55nqeividihc3s1jj60',
    IDENTITY_POOL_ID: '',
  },
  ga_id: 'UA-142071679-2',
  sentry_env: 'prod',
}

let config = local
if (process.env.application_environment === 'UAT') {
  config = uat
} else if (process.env.application_environment === 'PROD') {
  config = prod
} else {
  config = dev
}

export default {
  defaultPath: '/dispatch',
  basename: '', // only at build time to set, like /datta-able
  layout: 'vertical', // vertical, horizontal
  preLayout: 'layout-8', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: false, // mini-menu
  layoutType: 'menu-light', // menu-dark, menu-light, dark
  navIconColor: true,
  headerBackColor: 'header-lightblue', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: 'brand-lightblue', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: true,
  boxLayout: false,
  navDropdownIcon: 'style2', // style1, style2, style3
  navListIcon: 'style1', // style1, style2, style3, style4, style5, style6
  navActiveListColor: 'active-lightblue', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: false,
  layout6Background: 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)', // used only for pre-layout = layout-6
  layout6BackSize: '', // used only for pre-layout = layout-6
  sentry_dsn: 'https://e81394c7e9f94adcbbc2a7d6d83383b1@o200599.ingest.sentry.io/5388929',
  ...config,
}
