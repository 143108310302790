
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import detectBrowserLanguage from 'detect-browser-language'
import logger from 'redux-logger'

import rootReducer from './duck'
import config from './config'
import locales from './locale'
import { composeWithDevTools } from 'redux-devtools-extension'
import { getSelectedLanguage } from './App/ePOD/commons/utils/epodTokenUtils'

const autoLangMapping = {
  'zh-tw': 'zhHant',
  'zh-hk': 'zhHant',
  'es-ar': 'esES',
  'es-bo': 'esES',
  'es-cl': 'esES',
  'es-co': 'esES',
  'es-cr': 'esES',
  'es-do': 'esES',
  'es-ec': 'esES',
  'es-sv': 'esES',
  'es-gt': 'esES',
  'es-hn': 'esES',
  'es-mx': 'esES',
  'es-ni': 'esES',
  'es-pa': 'esES',
  'es-py': 'esES',
  'es-pe': 'esES',
  'es-pr': 'esES',
  'es-es': 'esES',
  'es-uy': 'esES',
  'es-ve': 'esES',
}

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['i18n'],
  stateReconciler: autoMergeLevel2, // 查看 'Merge Process' 部分的具体情况
}

const myPersistReducer = persistReducer(persistConfig, rootReducer)

// todo when debug then add reducer logger
const middlewares = [thunk]
if (config.debug) {
  middlewares.push(logger)
}

const store = createStore(myPersistReducer, composeWithDevTools(applyMiddleware(...middlewares)))

syncTranslationWithStore(store)
store.dispatch(loadTranslations(locales))

// default language
const browserLanguage = detectBrowserLanguage().toLowerCase()
const browserLanguageFirstTwo = browserLanguage.substr(0, 2)
const selectedLanguage = getSelectedLanguage() || browserLanguageFirstTwo

if (selectedLanguage in locales) {
  store.dispatch(setLocale(selectedLanguage))
} else {
  if (autoLangMapping[browserLanguage] in locales) {
    store.dispatch(setLocale(autoLangMapping[browserLanguage]))
  } else {
    store.dispatch(setLocale('en'))
  }
}

export const persistor = persistStore(store)

export default store
