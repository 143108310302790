export const CHANGE_LOADING_OVERLAY = 'CHANGE_LOADING_OVERLAY'

export function openLoadingOverlay() {
  return openOverlay('')
}

export function openSavingOverlay() {
  return openOverlay('')
}

export function openOverlay(text) {
  return {
    type: CHANGE_LOADING_OVERLAY,
    payload: {
      loadingOverlay: true,
      loadingOverlayText: text || '',
    },
  }
}

export function closeOverlay() {
  return {
    type: CHANGE_LOADING_OVERLAY,
    payload: {
      loadingOverlay: false,
      loadingOverlayText: '',
    },
  }
}

const initialState = {
  loadingOverlay: false,
  loadingOverlayText: '',
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_LOADING_OVERLAY:
      return {
        loadingOverlay: action.payload.loadingOverlay,
        loadingOverlayText: action.payload.loadingOverlayText || '',
      }
    default:
      return state
  }
}
