import { combineReducers } from 'redux'
import { i18nReducer } from 'react-redux-i18n'
import loadingOverlay from './loadingOverlay'

// Actions
const types = {
  CLEAR_STORE: 'CLEAR_STORE',
}

// Reducer
const appReducer = combineReducers({
  i18n: i18nReducer,
  loadingOverlay,
})

const rootReducer = (state, action) => {
  if (action.type === types.CLEAR_STORE) return appReducer({}, action)
  return appReducer(state, action)
}

// Action Creators
export const clearStore = () => ({
  type: types.CLEAR_STORE,
})

// Final reducer for createStore and persistReducer
export default rootReducer
