import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loader from './commons/components/Loader'
import routes from '../routes'

const FullPageRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((route, index) => {
          if (!route.component) return null
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(props) => <route.component {...props} />}
            />
          )
        })}
        <Redirect from='/' to={'/load-dispatch'} />
      </Switch>
    </Suspense>
  )
}

export default FullPageRoutes
