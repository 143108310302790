import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import config from '../../../config'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      toIndexPage: false,
      errorDetail: null,
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI

    // You can also log the error to an error reporting service
    // https://www.smashingmagazine.com/2020/06/react-error-handling-reporting-error-boundary-sentry/
    if (config.sentry_env !== 'local') {
      Sentry.withScope((scope) => {
        scope.setExtras(info)
        Sentry.captureException(error)
      })
    }

    if (error.name === 'ChunkLoadError' &&
      (error.request.endsWith('.js') || error.request.endsWith('.css'))
    ) {
      // save current page for redirect
      const current_path = window.location.pathname + window.location.search
      // redirect to index page
      this.setState({ toIndexPage: true })
      window.location.href = '/dispatch?redirect_path=' + encodeURI(current_path)
    } else {
      this.setState({ hasError: true })
      this.setState({ errorDetail: error })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='auth-wrapper'>
          <div className='auth-content'>
            <div className='card'>
              <div className='card-body text-center'>
                <h5 className='card-title'>
                  Need Technical Support......
                </h5>
                <p className='card-text'>
                  {this.state.errorDetail}
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
