import * as Sentry from '@sentry/react'
import { getAccessToken } from './epodTokenUtils'
import config from '../../../../config'

const error = (err, extraInfo) => {
  const token = getAccessToken()

  if (config.sentry_env !== 'local') {
    Sentry.withScope((scope) => {
      scope.setExtras({ extraInfo, token })
      Sentry.captureException(err)
    })
  }
}

export default { error }
