import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import _ from 'lodash'

import { closeOverlay } from '../../../duck/loadingOverlay'

const LoadingOverlayWapper = ({ children }) => {
  const {
    loadingOverlay,
    loadingOverlayText,
  } = useSelector(state => state.loadingOverlay, _.isEqual)

  const dispatch = useDispatch()

  const handleOverlay = useCallback(() => {
    dispatch(closeOverlay())
  }, [dispatch])

  // theme nagigation zIndex is 1029
  // Sweetalert zIndex is 1060 => set to 9001 in assets/mop/swal.scss
  const overlayStyles = useMemo(() => ({
    overlay: (oveylayStyles) => ({
      ...oveylayStyles,
      zIndex: 9000,
      opacity: oveylayStyles.opacity === 1 ? 0.6 : oveylayStyles.opacity,
    }),
    content: (oveylayStyles) => ({
      ...oveylayStyles,
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }),
  }), [])

  return (
    <LoadingOverlay
      active={loadingOverlay}
      text={loadingOverlayText}
      onClick={handleOverlay}
      spinner
      styles={overlayStyles}
    >
      { children }
    </LoadingOverlay>
  )
}

LoadingOverlayWapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoadingOverlayWapper
