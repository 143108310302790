import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import Logger from './LogUtil'

export const TOKEN_ATTRIBUTE = {
  ACCESS_TOKEN: 'DISPATCH_ACCESS_TOKEN',
}

export const hasValidAccessToken = () => {
  try {
    const token = sessionStorage.getItem(TOKEN_ATTRIBUTE.ACCESS_TOKEN)
    if (!token) return false

    const { exp } = jwt_decode(token)
    if (!exp) throw new Error('Error: Expire Time is Empty')

    return new Date() < new Date(Number(exp) * 1000)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    clearJWT()
    window.location.href = '/load-dispatch'
    return false
  }
}

export const getAccessToken = () => {
  return sessionStorage.getItem(TOKEN_ATTRIBUTE.ACCESS_TOKEN)
}

export const getDispatchNumber = () => {
  try {
    if (!hasValidAccessToken()) return ''

    const token = getAccessToken()
    const { dispatch_number = '' } = jwt_decode(token)
    return dispatch_number.toUpperCase()
  } catch (e) {
    Logger.error(e, { message: 'Failed to get dispatch number from token' })
    return ''
  }
}

export const getStation = () => {
  try {
    if (!hasValidAccessToken()) return ''

    const token = getAccessToken()
    const { station = '' } = jwt_decode(token)
    return station
  } catch (e) {
    Logger.error(e, { message: 'Failed to get station from token' })
    return ''
  }
}

export const getStationPhoneNumber = () => {
  try {
    if (!hasValidAccessToken()) return ''

    const token = getAccessToken()
    const { phone_number = '' } = jwt_decode(token)
    return phone_number
  } catch (e) {
    Logger.error(e, { message: 'Failed to get phone number from token' })
    return ''
  }
}

export const clearJWT = () => {
  const MOP_KEY = []
  for (var key in sessionStorage) {
    if (key.startsWith('DISPATCH')) {
      MOP_KEY.push(key)
    }
  }
  MOP_KEY.forEach((element) => { sessionStorage.removeItem(element) })
}

export const setToken = (access_token) => {
  sessionStorage.setItem(TOKEN_ATTRIBUTE.ACCESS_TOKEN, access_token)
}

export const setPolicyAgreeCookie = () => {
  const dispatchNumber = getDispatchNumber()
  Cookies.set(`${dispatchNumber}-policy-agree`, 'true', { expires: 1 })
}

export const getPolicyAgreeCookie = () => {
  const dispatchNumber = getDispatchNumber()
  return Cookies.get(`${dispatchNumber}-policy-agree`)
}

export const setSelectedLanguage = (locale) => {
  Cookies.set('selected-language', locale)
}

export const getSelectedLanguage = () => {
  return Cookies.get('selected-language')
}

export default {
  getAccessToken,
  setToken,
}
